
          // client side hydration entrypoint for a particular route generated by fastify-renderer
          import React from 'react'
          import ReactDOM from 'react-dom'
          import { routes } from "/@fstr!route-table.js?base=&lazy=true"
          import { Root } from "/app/node_modules/.pnpm/github.com+gadget-inc+fastify-renderer@23fd209cd5c17c54a15fc15e43ad23f55f287d16_fastify@4.24._oroaxp3dswwdwxnafzvtgxgik4/node_modules/fastify-renderer/client/react/index.js"
          import Layout from "web/src/components/auth/AuthLayout.tsx"
          import Entrypoint from "/@fs//app/packages/web/src/components/auth/LoginPage.tsx"

          ReactDOM.unstable_createRoot(document.getElementById('fstrapp'), {
            hydrate: true
          }).render(<Root
            Layout={Layout}
            Entrypoint={Entrypoint}
            basePath={""}
            routes={routes}
            bootProps={window.__FSTR_PROPS}
          />)
        